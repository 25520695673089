import { Box, Grommet, Text, Anchor, Heading, Grid } from 'grommet';
import { Form, DateInputField } from 'grommet-controls';
import { grommet } from 'grommet/themes';
import { createGlobalStyle } from 'styled-components';
import { connect } from 'react-redux';
import { StickyContainer, Sticky } from 'react-sticky';
import React, { Fragment } from 'react';
import { useMediaQuery } from 'react-responsive'
import siteConfig from '../../site-config';
// import { Stickyroll } from '@stickyroll/stickyroll';
import styled from 'styled-components';
import Img from "gatsby-image"

import siteTheme from '../site-theme';

import SiteFooter from './SiteFooter';
import SiteHeader from './SiteHeader';
import Sidebar from './Sidebar';
import Slider from './Slider';
import CheckoutBanner from './CheckoutBanner';

import { Animate, AnimateGroup } from 'react-simple-animate';



const BookingForm = styled(Form)`
  
& > div > div {
  min-width: ${props => props.isSmall ? '100%' : "90%"};
}
`


const BookingBox = styled(Box)`
  background: ${siteConfig.colors.accent};
  color: white;
  margin-top: ${props => props.isLarge ? '-260px' : props.isSmall ? '-10px' : "-100px"};

  z-index: 10;
  text-transform: uppercase;
  position: relative;
  min-height: 50px;
  span {
    white-space: nowrap;
  }

  button {
    padding: 2px 4px;
  }
  label {
    margin: 0;
    font-size: 12px;
  }
  input {
    padding: 2px;
  }

  div > input {
    border: 1px solid white;
  }
  input, svg {
    color: white;
    stroke: white;
    border-color: white;
    border-radius: 0;
  }

  .date {
    max-width: 200px;

    & > div:first-child {
      margin-bottom: 0;
    }
  }

  
 .book {

  padding: 4px 16px;
  background: white;
  color: ${siteConfig.colors.accent};
  white-space: nowrap;
  :hover {
    text-decoration: none;
    background: ${siteConfig.colors.brand};
    color: white;
  }

 }
`


const BookingBar = ({ browser, onClick }) => {

  const isLarge = browser.greaterThan.medium;
  const isSmall = browser.lessThan.medium;


  return (
    <Box direction='row' >
      {/* {isLarge && <Box basis='1/3' flex='grow'></Box>} */}
      <Box flex='grow'>

        <BookingForm className='container'             isSmall={isSmall}
>
          <BookingBox
            hoverIndicator
            isSmall={isSmall}
            isLarge={isLarge}
            onClick={onClick}
            align='center' pad='medium' gap={isSmall ? 'medium' : 'large'} direction='row' fill>
            <Text>Book Your Stay</Text>
            {
              !isSmall &&
              <Box className='date'>

                <DateInputField
                  label={isLarge ? 'Stay Date' : undefined}
                  // defaultValue={new Date()}
                  name='date'
                  date={new Date()}
                  // placeholder='DD/MM/YYYY'
                  onClick={onClick}
                // onChange={({ target: { value } }) => alert(value)}
                />
              </Box>
            }


            <Anchor
              className='book'

              label="Book Suite"
              // icon={<ContactIcon />}
              href="#" />

          </BookingBox>
        </BookingForm>
      </Box>
    </Box>

  )
}

export default BookingBar;