// import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Box, Heading, Anchor, Text, Stack, Button } from 'grommet';
import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';

import { TextFocus, TextBlur, TextPopUp, TextTypeWriter, TextShadow } from 'react-text-effects'
import { AnimateKeyframes, AnimateGroup } from 'react-simple-animate';
import { useMediaQuery } from 'react-responsive';
import { Next, Previous } from 'grommet-icons';
import styled from 'styled-components';

import { createGlobalStyle } from 'styled-components';

import { IconButton } from 'grommet-controls';
import { connect } from 'react-redux';

// Slider
import Slider from "react-slick";
import siteConfig from '../../site-config';
import BookingBar from './BookingBar';
import Actions from '../actions'


const GlobalStyle = createGlobalStyle`
  .slick-dots {
    bottom: ${props => props.isSmall ? '30px !important' : "50px !important"};


     & > li > button:before {
       font-size: 12px !important;
       -webkit-text-stroke: 1px rgba(255,255,255,0.5);
     }
  }

`;

const { colors } = siteConfig;

const BookingStamp = styled(Box)`
& > div {
  position: relative;
  bottom: ${props => props.isLarge ? '450px' : props.isSmall ? '-25px' : "100px"};
  left: ${props => props.isLarge ? '-630px' : "0"};
  z-index: 10;
  height: 240px;
  width: 240px;
}
`


const Hero = styled(Box)`


  position: relative;
  z-index: 10;
  background: rgba(255,255,255,1);
  bottom: ${props => props.isLarge ? '480px' : props.isSmall ? '10px' : "100px"};
  left: ${props => props.isLarge ? '-320px' : "0"};
  width: ${props => props.isLarge ? '540px' : props.isSmall ? '100%' : "90%"};
  padding: ${props => props.isLarge ? '20px 40px' : props.isSmall ? '0 10px' : "0 50px"};
  text-align: left;
  min-height: ${props => props.isSmall ? '172px' : "0"};

  h1{
    z-index:3;
    margin-bottom: 0;
    text-transform: uppercase;
    // font-size: 36px;
    font-weight: 400;
    letter-spaceing: 2px;
    white-space: nowrap;
  }
  h4 {
    z-index:3;
    padding: 4px;
    font-weight: 300;
    margin-top: 2px;
    max-width: ${props => props.isLarge ? '480px' : "90%"};
    
  }

`;


const NavButton = styled(Button)`
& > svg {
  width: 48px;
  height: 48px;
  stroke: white;
}
`

const SliderWrapper = styled(Box)`
  position: relative;
`;


const settings = {
  // dots: true,
  infinite: true,
  speed: 500,
  arrows: false,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  fade: true,
  pauseOnDotsHover: true,
  // dotsClass: 'slider-dots',
  // nextArrow: <NextArrow />,
  // prevArrow: <PrevArrow />,
  dots: true
};





const MainSlider = ({ images, browser, showBookingLayer, bookingImage }) => {
  const isLarge = browser.greaterThan.medium;
  const isSmall = browser.lessThan.medium;
  // console.info(isSmall)
  return (
    <Box direction='row' >
      {isLarge && <Box basis='1/3' flex='grow'></Box>}
      <SliderWrapper basis='2/3' flex='grow'>

        <GlobalStyle isSmall={isSmall} />

        <Slider {...settings} style={isLarge ? { minWidth: '960px' } : {}}>
          {/* <Stack fill> */}
          {/* <Heading>Your Home in Tel Aviv</Heading> */}
          {images && images.mainSlider.map((edge, i) =>
            <Box key={i}>
              {
                // isSmall
                // ?
                // <AnimateKeyframes
                //   key={`${edge.node.fluid.src}_anim`}
                //   play={isSmall}
                //   // pause={true}
                //   delay={0}
                //   duration={8}
                //   iterationCount={4}
                //   direction="alternate"
                //   keyframes={isSmall ? [
                //     'transform: translateX(0)',
                //     'transform: translateX(-200px)',
                //   ] : []}
                //   easeType="cubic-bezier(0.445, 0.05, 0.55, 0.95)"
                // // onComplete={onCompleteCallBack} // call back function when animation is completed
                // >
                //   <Img fluid={edge.node.fluid} style={{ /*height: '600px'*/ }} />
                // </AnimateKeyframes>
                // :

                <Img key={`${edge.node.fluid.src}_stat`}
                  fluid={edge.node.fluid} style={{
                    // height: '600px' 
                  }} />


              }


            </Box>

          )}
          {/* </Stack> */}

        </Slider>

        <Hero align='start' justify='center' flex='shrink' isLarge={isLarge} isSmall={isSmall}>
          <Heading level='1'>
            {/* <TextFocus type='expand' duration='1s'> */}
            Your Home in TLV.
            {/* </TextFocus> */}
          </Heading>
          <Heading level='4'>
            {/* <TextFocus type='in' duration='1s'> */}
            Mix the comfort of home with the freedom of travel and open up possibilities beyond just the business you came for.
          {/* </TextFocus> */}
          </Heading>
        </Hero>

        <BookingBar browser={browser} onClick={showBookingLayer} />
        <BookingStamp
          align='center'
          justify='center'
          isSmall={isSmall}
          isLarge={isLarge}>
          <Box>
            <Img key={`${bookingImage[0].node.fluid.src}_stat`}
              fluid={bookingImage[0].node.fluid} style={{
                // height: '600px' 
              }} />
          </Box>
        </BookingStamp>
      </SliderWrapper>


    </Box >
  );

}


const mapStateToProps = ({ browser }) => {
  return { browser };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(Actions, dispatch)
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainSlider);
