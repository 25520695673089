// import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Box, Heading, Anchor, Text, Stack, Button } from 'grommet';
import React, { Fragment } from 'react';
import { TextFocus, TextBlur, TextPopUp, TextTypeWriter, TextShadow } from 'react-text-effects'
import { AnimateKeyframes, AnimateGroup } from 'react-simple-animate';
import { useMediaQuery } from 'react-responsive';
import { Next, Previous } from 'grommet-icons';
import styled from 'styled-components';
import { IconButton } from 'grommet-controls';
import { connect } from 'react-redux';
import { createGlobalStyle } from 'styled-components';

// Slider
import Slider from "react-slick";
import siteConfig from '../../site-config';
const { colors } = siteConfig;

const SliderWrapper = styled(Box)`
  position: relative;

.bottom {
  position:relative;
  bottom: 200px;
  padding: 100px 20px 20px 20px;
  background: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, black 67%);
  
}
`

// position: absolute;
// z-index: 10;
// background: rgba(255,255,255,1);
// bottom: 200px;
// left: -300px;
// width: 500px;

const Hero = styled(Box)`


  position: relative;
  z-index: 10;
  background: rgba(255,255,255,1);
  bottom: ${props => props.isLarge ? '450px' : props.isSmall ? '50px' : "120px"};

  left: ${props => props.isLarge ? '-300px' : "0"};
  width: ${props => props.isLarge ? '540px' : props.isSmall ? '100%' : "90%"};
  padding: ${props => props.isLarge ? '50px' : props.isSmall ? '0 10px' : "0 50px"};
  text-align: left;
  min-height: ${props => props.isSmall ? '172px' : "0"};

  h2{
    z-index:3;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 400;
    letter-spaceing: 2px;
  }
  h4 {
    z-index:3;
    padding: 4px;
    font-weight: 300;
    margin-top: 2px;
    max-width: ${props => props.isLarge ? '480px' : "90%"};
    
  }
  .seemore {
    border: 1px solid ${colors.brand};
    padding: 4px 16px;
    color: ${colors.brand};
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;

    :hover {
      text-decoration: none;
      text-shadow: 2px 2px 2px rgba(255,255,255,0.2);
      background: ${colors.brand};
      color: white;
    }

`;
const GlobalStyle = createGlobalStyle`
  .slick-dots {
    bottom: ${props => props.isSmall ? '30px !important' : "50px !important"};


     & > li > button:before {
       font-size: 12px !important;
       -webkit-text-stroke: 1px rgba(255,255,255,0.5);
     }
  }

`;


const settings = {
  // dots: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  fade: true,
  pauseOnDotsHover: true,

  dots: true

  // nextArrow: <NextArrow />,
  // prevArrow: <PrevArrow />,
  // dots: true
};





const MainSlider = ({ images, height, browser }) => {

  const isLarge = browser.greaterThan.medium;
  const isSmall = browser.lessThan.medium;

  return (
    <Box direction='row' pad={{ vertical: 'none' }}>
      {isLarge && <Box basis='1/3' flex='grow'></Box>}
      <SliderWrapper basis='2/3' flex='grow' >
        <GlobalStyle isSmall={isSmall} />

        <Slider {...settings} style={isLarge ? { minWidth: '960px' } : {}}>
          {/* <Stack fill> */}
          {/* <Heading>Your Home in Tel Aviv</Heading> */}
          {images && images.map((edge, i) =>
            <Box key={i}>
              {/* {isSmall
                ?
                <AnimateKeyframes
                  key={`${edge.node.fluid.src}_anim`}
                  play={isSmall}
                  // pause={true}
                  delay={0}
                  duration={8}
                  iterationCount={4}
                  direction="alternate"
                  keyframes={isSmall ? [
                    'transform: translateX(0)',
                    'transform: translateX(-100px)',
                  ] : []}
                  easeType="cubic-bezier(0.445, 0.05, 0.55, 0.95)"
                // onComplete={onCompleteCallBack} // call back function when animation is completed
                >
                  <Img fluid={edge.node.fluid} style={{ height: '500px' }} />
                </AnimateKeyframes>
                : */}

              <Img key={`${edge.node.fluid.src}_stat`}
                fluid={edge.node.fluid} style={{
                  // height: '500px' 

                }} />


              {/* }
 */}

            </Box>

          )}
          {/* </Stack> */}

        </Slider>

        <Hero align='start' justify='center' flex='shrink' isLarge={isLarge} isSmall={isSmall}>
          <Heading level='2'>
            {/* <TextFocus type='expand' duration='1s'> */}
            Your Home
          {/* </TextFocus> */}
          </Heading>
          <Heading level='4'>
            We made sure you will feel at home and cozy in our apartment so you can enjoy your travel to the max.
       </Heading>
          <Anchor
            className='seemore'

            label="See More"
            // icon={<ContactIcon />}
            href="/location" />
        </Hero>
        {/* 
        <Box className='bottom'>
          {" "}
        </Box> */}
      </SliderWrapper>



    </Box >
  );

}


const mapStateToProps = ({ browser }) => {
  return { browser };
};

const mapDispatchToProps = (dispatch) => {
  return {}
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainSlider);
