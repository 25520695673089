import { Box, Grommet, Text, Anchor, Heading, Grid } from 'grommet';
import { Form, DateInputField } from 'grommet-controls';
import { grommet } from 'grommet/themes';
import { createGlobalStyle } from 'styled-components';
import { connect } from 'react-redux';
import { StickyContainer, Sticky } from 'react-sticky';
import React, { Fragment } from 'react';
import { useMediaQuery } from 'react-responsive'
import siteConfig from '../../site-config';
// import { Stickyroll } from '@stickyroll/stickyroll';
import styled from 'styled-components';
import Img from "gatsby-image"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import siteTheme from '../site-theme';

import SiteFooter from './SiteFooter';
import SiteHeader from './SiteHeader';
import Sidebar from './Sidebar';
import Slider from './Slider';
import CheckoutBanner from './CheckoutBanner';

import { Animate, AnimateGroup } from 'react-simple-animate';
const { colors } = siteConfig;


const mapStyleArray = [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#46bcec"
            },
            {
                "visibility": "on"
            }
        ]
    }
];

// https://snazzymaps.com/style/93/lost-in-the-desert

const StyledMap = styled(Box)`
    margin-top: ${props => props.isLarge ? '-100px' : '-50px'};
  .content {
        width: ${props => props.isLarge ? '70vw' : '100%'};
    background: ${colors.accent};
    margin-top: ${props => props.isLarge ? '-200px' : '0'};
      }
  .heading {
        color: white;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 2px;
    }
  span {
      color: white;
      max-width: 450px;
      font-weight: 300;
    }
    .readmore {
      border: 1px solid white;
      padding: 4px 16px;
      color: white;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 400;

      :hover {
        text-decoration: none;
        text-shadow: 2px 2px 2px rgba(255,255,255,0.2);
        background: ${colors.accent};
        color: white;
      }
    }
  `


  
const MyMapComponent = withScriptjs(withGoogleMap((props) =>
<GoogleMap
  defaultZoom={15}
  defaultCenter={{ lat: 32.053862, lng: 34.760425 }}
  defaultOptions={{ styles: mapStyleArray }}

  
>
<Marker position={{ lat: 32.053862, lng: 34.760425 }} />

  {/* {props.isMarkerShown && <Marker position={{ lat: -34.397, lng: 150.644 }} />} */}
</GoogleMap>
))

const MapBox = ({ browser, images }) => {

    const isLarge = browser.greaterThan.medium;
    const isSmall = browser.lessThan.large;


    return (
        <StyledMap
            isLarge={isLarge}
            fill='horizontal'
            align='center'
            justify='center'
            pad={
                {
                    horizontal: isSmall ? 'none' : 'large',
                    vertical: 'xlarge'
                }
            }
        >

            <Box direction={isLarge ? 'row' : 'column'} className='content' pad='medium'>
                <Box basis='1/2'>
                    <MyMapComponent
                      googleMapURL={"https://maps.googleapis.com/maps/api/js?key=AIzaSyBjve60K9SArbZe6ibF9Qpreu6pfap7w8w&v=3.exp&libraries=geometry,drawing,places"}
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ minHeight: `300px`, height: '100%' }} />}
                      mapElement={<div style={{ height: `100%`, minHeight: `300px` }} />}
                    />
                </Box>
                <Box pad={{horizontal: 'medium', vertical: isSmall ? 'large' : 'medium'}}>
                    <Heading level='3' className='heading'>Explore The City</Heading>
                    <Text>There's so much to experience around that you'll probably need to visit here again. The Beach, Markets, Old Jaffa, Museums, Restaurants, Bars, Hummus and much much more!</Text>
                    {/* <Box align='start' justify='start' pad='small'>
                        <Anchor
                            className='readmore'

                            label="Read More"
                            // icon={<ContactIcon />}
                            href="/Map" />

                    </Box> */}

                </Box>

                {/* https://theculturetrip.com/middle-east/israel/articles/the-best-things-to-see-and-do-in-tel-aviv/ */}

            </Box>
        </StyledMap>

    )
}

export default MapBox;