import { Box, Grommet, Text, Anchor, Heading, Grid } from 'grommet';
import { Form, DateInputField } from 'grommet-controls';
import { grommet } from 'grommet/themes';
import { createGlobalStyle } from 'styled-components';
import { connect } from 'react-redux';
import { StickyContainer, Sticky } from 'react-sticky';
import React, { Fragment } from 'react';
import { useMediaQuery } from 'react-responsive'
import siteConfig from '../../site-config';
// import { Stickyroll } from '@stickyroll/stickyroll';
import styled from 'styled-components';
import Img from "gatsby-image"

import siteTheme from '../site-theme';

import SiteFooter from './SiteFooter';
import SiteHeader from './SiteHeader';
import Sidebar from './Sidebar';
import Slider from './Slider';
import CheckoutBanner from './CheckoutBanner';

import { Animate, AnimateGroup } from 'react-simple-animate';
const { colors } = siteConfig;

const StyledLocation = styled(Box)`
  .content {
        width: ${props => props.isLarge ? '70vw' : '100%'};
        margin-top: ${props => props.isLarge ? '-400px' : '0'};

    background: ${colors.brand}
      }
  .heading {
        color: white;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 2px;
    }
  span {
      color: white;
      max-width: ${props => props.isMediumPlus ? '450px' : '100%'};
      font-weight: 300;
    }
    .seemore {
      border: 1px solid white;
      padding: 4px 16px;
      color: white;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 400;

      :hover {
        text-decoration: none;
        text-shadow: 2px 2px 2px rgba(255,255,255,0.2);
        background: ${colors.brand};
        color: white;
      }
    }
  `


const LocationBox = ({ browser, images }) => {

  const isLarge = browser.greaterThan.medium;
  const isMediumPlus = browser.greaterThan.small;
  const isSmall = browser.lessThan.large;


  return (
    <StyledLocation
      isLarge={isLarge}
      isMediumPlus={isMediumPlus}

      fill='horizontal'
      align='center'
      justify='center'
      pad={
        {
          horizontal: isLarge ? 'large' : 'none',
          vertical: 'xlarge'
        }
      }
    >

      <Box direction={isLarge ? 'row' : 'column'} className='content' pad={isLarge ? 'medium' : 'none'} >
        <Box basis='1/2'>
          {images && <Img fluid={images.location[0].node.fluid} style={{ minHeight: isLarge ? '300px' : '200px' }} />}
          {/* {console.info(images.locatio/n)} */}
        </Box>
        <Box pad={{ horizontal: 'medium', vertical: 'medium' }}>
          <Heading level='3' className='heading' margin={isSmall ? '6px 0' : {vertical: 'medium'}}>Experience Tel Aviv</Heading>
          <Text>A compact city brimming with a diverse and vibrant cultural scene, exceptional culinary offerings and lively nightlife, Tel Aviv is a metropolis that lives around the clock.</Text>
          <Box align='start' justify='start' pad={{ horizontal: 'none', vertical: 'medium' }}>
            <Anchor
              className='seemore'

              label="See More"
              // icon={<ContactIcon />}
              href="/location" />

          </Box>

        </Box>

        {/* https://theculturetrip.com/middle-east/israel/articles/the-best-things-to-see-and-do-in-tel-aviv/ */}

      </Box>
    </StyledLocation>

  )
}

export default LocationBox;