import { graphql } from 'gatsby';
import * as _ from 'lodash/fp';
import * as React from 'react';
import Helmet from 'react-helmet';
import { Box, Grommet, Text, Anchor, Heading, Grid } from 'grommet';
import { connect } from 'react-redux';
import styled from 'styled-components';
import siteConfig from '../../site-config';

import Layout from '../components/Layout';
// import console = require('console');
import Slider from '../components/Slider';
// import BookingBar from '../components/BookingBar';
import LocationBox from '../components/LocationBox';
import MapBox from '../components/MapBox';
import Space from '../components/Space';

interface BlogIndexProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        description: string;
      };
    };
    allMarkdownRemark: {
      edges: any;
    };
    allImageSharp: {
      edges: any;
    };
  };
}



const BlogIndex = ({ data, browser }: BlogIndexProps) => {
  const siteTitle = data.site.siteMetadata.title;
  const siteDescription = data.site.siteMetadata.description;
  // const posts = data.allMarkdownRemark.edges;
  const mainSliderImages = data.allImageSharp.edges.filter(obj => obj.node.fluid.src.indexOf('MS-') > -1);
  const spaceImages = data.allImageSharp.edges.filter(obj => obj.node.fluid.src.indexOf('SS-') > -1);

  const locationImage = data.allImageSharp.edges.filter(obj => obj.node.fluid.src.indexOf('tlvjaffa') > -1);

  const bookingImage = data.allImageSharp.edges.filter(obj => obj.node.fluid.src.indexOf('booking') > -1);

  console.info({bookingImage})

  // console.info({ mainSliderImages, locationImage })
  const images = { mainSlider: mainSliderImages, location: locationImage, spaceSlider: spaceImages }
  return (
    <Layout images={images}>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        meta={[{ name: 'description', content: siteDescription }]}
        title={siteTitle}>
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,500" />
        <meta name="theme-color" content={siteConfig.colors.accent}></meta>
      </Helmet>
      <main>
        <Slider images={images} bookingImage={bookingImage}></Slider>
        {/* <BookingBar browser={browser} /> */}
        <LocationBox {...{ images, browser }} />
        <Space images={spaceImages}></Space>
        <MapBox {...{ browser }} />

      </main>
    </Layout>
  );
};



const mapStateToProps = ({ themeType, browser }) => {
  return { themeType, browser };
};

const mapDispatchToProps = (dispatch) => {
  return { themeSwitch: () => dispatch({ type: `CHANGE_THEME` }) };
};

const ConnectedBlogIndex = connect(
  mapStateToProps,
  mapDispatchToProps
)(BlogIndex);



export default ConnectedBlogIndex;



export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allImageSharp(filter: {original: {src: {regex: "/MS|tlv|SS|booking/"}}}) {
      edges {
        node {
          id
          fluid(maxHeight: 1200) {
            ...GatsbyImageSharpFluid
            }
        }
      }
    }
  }
`;

          // fluid(maxHeight: 500) {
          //   ...GatsbyImageSharpFluid
          // }